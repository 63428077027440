<script setup lang="ts">
const props = defineProps<{
  data?: any
}>()
</script>

<template>
  <div class="space-y-4 text-center pb-5 lg:pb-16">
    <h1 class="text-4xl font-bold text-gray-800 sm:text-5xl">
      {{ props?.data?.name }}
    </h1>
    <p v-if="props?.data?.summary || props?.data?.caption" class="text-xl text-gray-800 lg:mx-auto lg:w-6/12">
      {{ props?.data?.summary || props?.data?.caption }}
    </p>
    <ShopCard v-if="props?.data?.shopdata" :data="props?.data?.shopdata" />
  </div>
</template>
