<script setup lang="ts">
const props = defineProps<{
  data?: any
  isshopname?: boolean
}>()

const { t } = useI18n()
const localePath = useLocalePath()
</script>

<template>
  <div v-if="props?.data" class="space-y-2 mx-auto">
    <ULink
      v-if="props?.data?.shop_name && props?.isshopname"
      :to="localePath(`/shop/${props?.data?.shop_slug}-${props?.data?.shop_id}`)"
      class="text-xl text-gray-600 font-bold"
    >
      {{ props?.data?.shop_name }}
    </ULink>
    <p v-if="props?.data?.address" class="text-xl text-gray-600">
      {{ `${t('address')}: ${props?.data?.address}` }}
    </p>
    <p v-if="props?.data?.phone" class="text-xl text-gray-600">
      {{ `${t('phone')}: ${props?.data?.phone}` }}
    </p>
    <p v-if="props?.data?.email" class="text-xl text-gray-600">
      {{ `${t('email')}: ${props?.data?.email}` }}
    </p>
  </div>
</template>
